import { Action, Contact, Order, OrderFailureType, Restaurant } from '@wix/restaurants-client-logic';
import { Dispatch } from 'redux';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/cjs/flow-api/ViewerScript';
import { setErrorType, submitOrderSuccess } from './checkout.actions';
import { clearSessionStorage, navigate, openModal } from '../session/session.actions';
import { Modals, RouteUrls } from '../../core/constants';
import { GetMyMemberResponse, MembersNgApi, Contact as MembersApiContact } from '@wix/ambassador-members-ng-api/http';
import _ from 'lodash';
import { getSignedInstance } from '../session/session.probe';
import { AppState } from '../createStore';
import { componentSettings } from '../../components/MainPage/componentSettings';

export function handleOrderSuccess({
  orderResponse,
  dispatch,
  flowAPI,
  requestId = '',
}: {
  orderResponse: Order;
  dispatch: Dispatch<Action<any>>;
  flowAPI: ControllerFlowAPI;
  requestId?: string;
}) {
  dispatch(submitOrderSuccess({ orderResponse }));
  dispatch(navigate({ routeUrl: RouteUrls.THANK_YOU }));
  dispatch(clearSessionStorage());
  if (flowAPI.biLogger) {
    flowAPI.biLogger.submitOrderSuccess({
      orderId: orderResponse.id,
      oloRequestId: requestId,
    });
  }
}

export const getMemberContactDetails = async (flowAPI: ControllerFlowAPI, state: AppState) => {
  if (
    !flowAPI.controllerConfig.wixCodeApi.user.currentUser.loggedIn ||
    !flowAPI.settings.get(componentSettings.hasMembersAreaIntegration)
  ) {
    return;
  }

  const experiments = await flowAPI.getExperiments();
  const isExperimentEnabled = experiments.enabled('specs.restaurants.olo-client-members-area-contact');

  if (!isExperimentEnabled) {
    return;
  }

  const signedInstance = getSignedInstance(flowAPI);
  const headers = { Authorization: signedInstance };
  const membersApi = MembersNgApi('/_api/members').Members()(headers);
  const { member }: GetMyMemberResponse = await membersApi.getMyMember({ fieldSet: 'FULL' });
  return member?.contact;
};

export function handleOrderFailure({
  orderResponseType = '',
  orderResponseDetail = '',
  orderResponseCode,
  orderResponseChargeId,
  dispatch,
  flowAPI,
  requestId = '',
}: {
  orderResponseType?: string;
  orderResponseDetail?: string;
  orderResponseCode?: number;
  orderResponseChargeId?: string;
  dispatch: Dispatch<Action<any>>;
  flowAPI: ControllerFlowAPI;
  requestId?: string;
}) {
  let errorType = '';
  if (orderResponseType) {
    errorType = orderResponseType.substring(orderResponseType.lastIndexOf('/') + 1);
  }
  dispatch(
    setErrorType({
      errorType: errorType as OrderFailureType,
      errorCode: orderResponseCode,
      errorChargeId: orderResponseChargeId,
    }),
  );

  dispatch(openModal({ modal: Modals.ORDER_FAILURE_MODAL }));
  if (flowAPI.biLogger) {
    flowAPI.biLogger.submitOrderFailure({
      rejectReason: orderResponseType,
      oloRequestId: requestId,
    });
    if (orderResponseDetail && orderResponseDetail.includes('earliest allowed delivery.time')) {
      flowAPI.biLogger.futureOrdersError({ details: orderResponseDetail.split('order')[0] });
    }
  }
}

export function reportSubmitOrderBiEvent(
  flowAPI: ControllerFlowAPI,
  restaurant: Restaurant,
  requestId: string,
  total: number,
  totalItemsCount: number,
  isContactless: boolean,
) {
  if (flowAPI.biLogger) {
    flowAPI.biLogger.submitOrder({
      currency: restaurant.currency,
      oloRequestId: requestId,
      total,
      totalItemsCount,
      isContactless,
    });
  }
}

export async function fillInMissingContactDataInMembersArea(
  flowAPI: ControllerFlowAPI,
  oloContact: Contact,
  membersApiContact?: MembersApiContact,
) {
  if (!flowAPI.controllerConfig.wixCodeApi.user.currentUser.loggedIn) {
    return;
  }

  const experiments = await flowAPI.getExperiments();
  const isExperimentEnabled = experiments.enabled('specs.restaurants.olo-client-members-area-contact');

  if (!isExperimentEnabled) {
    return;
  }

  if (membersApiContact) {
    const { shouldBeUpdated, contact } = constructContactToBeUpdated(membersApiContact, oloContact);
    if (shouldBeUpdated) {
      const signedInstance = getSignedInstance(flowAPI);
      const headers = { Authorization: signedInstance };
      const membersApi = MembersNgApi('/_api/members').Members()(headers);

      await membersApi.updateMember({
        member: { id: flowAPI.controllerConfig.wixCodeApi.user.currentUser.id, contact },
      });
    }
  }
}

function constructContactToBeUpdated(membersApiContact: MembersApiContact, oloContact: Contact) {
  const shouldBeUpdated = !(
    membersApiContact.phones?.find((phone) => phone === oloContact.phone) &&
    membersApiContact.firstName === oloContact.firstName &&
    membersApiContact.lastName === oloContact.lastName &&
    membersApiContact.emails?.find((email) => email === oloContact.email)
  );
  const contact: MembersApiContact = {};
  if (shouldBeUpdated) {
    contact.firstName = membersApiContact.firstName === oloContact.firstName ? undefined : oloContact.firstName;
    contact.lastName = membersApiContact.lastName === oloContact.lastName ? undefined : oloContact.lastName;
    if (!membersApiContact.phones) {
      contact.phones = [oloContact.phone];
    }

    if (
      membersApiContact.phones &&
      !(membersApiContact.phones[0] && membersApiContact.phones[0] === oloContact.phone)
    ) {
      contact.phones = [...membersApiContact.phones];
      contact.phones.shift();
      contact.phones.unshift(oloContact.phone);
    }
  }
  return {
    shouldBeUpdated,
    contact,
  };
}

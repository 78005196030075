import { ProbeArgument } from '../createStore';
import {
  clearSessionStorage,
  initApp,
  saveAddressToServer,
  saveStateToSessionStorage,
  setIsLoadingAddressesFromServer,
  setIsUserLoggedIn,
  setSavedAddresses,
  setSignedInstance,
  userLoggedIn,
  changeLocation,
  navigate,
  setIsLoadingLocationFromServer,
  setOrganizationFull,
  setLocations,
  closeModal,
} from './session.actions';
import { clearCart } from '../cart/cart.actions';
import { Modals, RouteUrls, SESSION_STORAGE_KEY } from '../../core/constants';
import moment from 'moment-timezone';
import { AddressesWeb, ListResponse } from '@wix/ambassador-addresses-web/http';
import {
  setCheckoutStep,
  setDeliveryAddress,
  setMemberContactLoading,
  setMembersAPiContact,
  setSelectedAddressId,
} from '../checkout/checkout.actions';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/cjs/flow-api/ViewerScript';
import { Dispatch } from 'redux';
import { Action, getOrganizationAndMenu } from '@wix/restaurants-client-logic';
import WixInstance from '@wix/wixrest-utils/dist/WixInstance';
import { ChangeLocationPayload, SaveAddressToServerPayload } from './session.actions.types';
import { getMemberContactDetails } from '../checkout/checkout.probe.utils';
import { convertMembersAddressToOloAddress, getUpdateAddressObjectPaths } from '../../core/logic/addressLogic';
import { getLocationsSettings } from '../../core/oloApi';
import { getBaseUrlForMappedServices } from '../../core/logic/urlLogic';

export default function sessionProbe({ onAction, onActionOnce }: ProbeArgument) {
  onAction(initApp.toString(), async (action, getState, dispatch, { flowAPI }) => {
    const experiments = await flowAPI.getExperiments();
    const isMLEnabled = experiments.enabled('specs.restaurants.olo-client-ml');
    const isMembersContactEnabled = experiments.enabled('specs.restaurants.olo-client-members-area-contact');
    const isMembersWalletEnabled = experiments.enabled('specs.restaurants.olo-client-members-area-my-wallet');

    const signedInstance = getSignedInstance(flowAPI);
    const restaurantId = getState().session.restaurant.id;
    const baseUrlForMappedServices = getBaseUrlForMappedServices({
      websiteUrl: flowAPI.controllerConfig.wixCodeApi.location.baseUrl,
      isSSR: flowAPI.environment.isSSR,
    });

    if (flowAPI.biLogger) {
      flowAPI.biLogger.initSession({ projectName: undefined } as any);
    }
    dispatch(
      setIsUserLoggedIn({ isLoggedIn: flowAPI.controllerConfig.wixCodeApi.user.currentUser.role !== 'Visitor' }),
    );

    const { isMembersAddressEnabled, shouldInitMemberAddresses } = await checkMembersAreaPrerequisits(flowAPI);

    if (shouldInitMemberAddresses) {
      await initMemberAddresses(flowAPI, dispatch);
    }

    if ((isMembersAddressEnabled || isMembersContactEnabled || isMembersWalletEnabled) && !flowAPI.environment.isSSR) {
      flowAPI.controllerConfig.wixCodeApi.user.onLogin(() => {
        dispatch(userLoggedIn());
      });
    }

    if (isMLEnabled) {
      const locations = await getLocationsSettings(signedInstance, restaurantId, baseUrlForMappedServices);
      dispatch(setLocations({ locations }));
    }
  });

  onActionOnce(userLoggedIn.toString(), async (action, getState, dispatch, { flowAPI }) => {
    dispatch(setCheckoutStep({ step: 'address-information' }));
    dispatch(setIsUserLoggedIn({ isLoggedIn: true }));
    dispatch(setSignedInstance({ signedInstance: getSignedInstance(flowAPI) }));
    const { shouldInitMemberAddresses } = await checkMembersAreaPrerequisits(flowAPI);

    if (shouldInitMemberAddresses) {
      await initMemberAddresses(flowAPI, dispatch);
    }

    dispatch(setMemberContactLoading({ loading: true }));
    const contact = await getMemberContactDetails(flowAPI, getState());
    if (contact) {
      dispatch(setMembersAPiContact({ contact }));
    }
    dispatch(setMemberContactLoading({ loading: false }));
  });

  onAction(saveStateToSessionStorage.toString(), (action, getState, dispatch, { flowAPI }) => {
    const { cart, checkout, addressForm } = getState();
    const { orderItems, coupon, comment } = cart;
    const { checkoutStep, contact } = checkout;
    const { selectedAddressOption } = addressForm;
    const timestamp = moment().valueOf();

    flowAPI.controllerConfig.platformAPIs.storage.session.setItem(
      SESSION_STORAGE_KEY,
      JSON.stringify({
        orderItems,
        coupon,
        comment,
        dispatch: checkout.dispatch,
        checkoutStep,
        contact,
        selectedAddressOption,
        timestamp,
      }),
    );
  });

  onAction(clearSessionStorage.toString(), (action, getState, dispatch, { flowAPI }) => {
    flowAPI.controllerConfig.platformAPIs.storage.session.removeItem(SESSION_STORAGE_KEY);
  });

  onAction(
    saveAddressToServer.toString(),
    async (action: Action<SaveAddressToServerPayload>, getState, dispatch, { flowAPI }) => {
      const signedInstance = getSignedInstance(flowAPI);
      const headers = { Authorization: signedInstance };
      const addressesService = AddressesWeb('/_api/addresses-web').Addresses()(headers);
      const { address, addressId, setAsDefault } = action.payload;

      dispatch(setIsLoadingAddressesFromServer({ isLoadingAddressesFromServer: true }));

      if (addressId) {
        const paths = getUpdateAddressObjectPaths(address);
        if (setAsDefault) {
          paths.push('setAsDefault');
        }
        await addressesService.update({ address: { id: addressId, ...address }, setAsDefault, fieldMask: { paths } });
        dispatch(setSelectedAddressId({ id: addressId }));
      } else {
        const { id } = await addressesService.create({ address, setAsDefault });
        dispatch(setSelectedAddressId({ id }));
      }

      const { addresses, defaultAddressId } = await fetchMemberAddressesData(flowAPI);
      dispatch(setSavedAddresses({ addresses, defaultAddressId }));
      dispatch(closeModal({ modal: Modals.ADDRESS_SELECTION }));
      dispatch(setIsLoadingAddressesFromServer({ isLoadingAddressesFromServer: false }));
    },
  );

  onAction(
    changeLocation.toString(),
    async (action: Action<ChangeLocationPayload>, getState, dispatch, { flowAPI }) => {
      /**
       * 1. clear cart
       * 2. indicate fetching menu
       * 3. fetch new menu and org and replace the old one
       * 4. navigate to menus
       */
      const signedInstance = getSignedInstance(flowAPI);

      dispatch(setIsLoadingLocationFromServer({ isLoadingLocationFromServer: true }));
      const full = await getOrganizationAndMenu(signedInstance, action.payload.locationId, true);
      if (full) {
        dispatch(clearCart());
        dispatch(setOrganizationFull({ organizationFull: full }));
      }
      dispatch(setIsLoadingLocationFromServer({ isLoadingLocationFromServer: false }));
      dispatch(closeModal({ modal: Modals.CHANGE_LOCATION_MODAL }));
      // dispatch(initCheckout());
      // dispatch(clearSessionStorage());
      dispatch(navigate({ routeUrl: RouteUrls.INITIAL_VIEW }));
    },
  );
}

async function checkMembersAreaPrerequisits(flowAPI: ControllerFlowAPI) {
  const experiments = await flowAPI.getExperiments();
  const isMembersAddressEnabled = experiments.enabled('specs.restaurants.olo-client-members-area-addresses');
  const isUserLoggedIn = flowAPI.controllerConfig.wixCodeApi.user.currentUser.role !== 'Visitor';

  return {
    isMembersAddressEnabled,
    isUserLoggedIn,
    shouldInitMemberAddresses: isMembersAddressEnabled && isUserLoggedIn,
  };
}

export function getSignedInstance(flowAPI: ControllerFlowAPI) {
  return (
    flowAPI.controllerConfig.wixCodeApi.site.getAppToken?.(WixInstance.ORDERS_APP_ID) ||
    flowAPI.controllerConfig.appParams.instance
  );
}

async function fetchMemberAddressesData(flowAPI: ControllerFlowAPI) {
  const signedInstance = getSignedInstance(flowAPI);
  const headers = { Authorization: signedInstance };
  const addressesService = AddressesWeb('/_api/addresses-web').Addresses()(headers);
  const { addresses = [], defaultAddressId }: ListResponse = await addressesService.list({});
  return { addresses: addresses.filter((a) => Boolean(a.addressLine1)), defaultAddressId };
}

async function initMemberAddresses(flowAPI: ControllerFlowAPI, dispatch: Dispatch<Action<any>>) {
  dispatch(setIsLoadingAddressesFromServer({ isLoadingAddressesFromServer: true }));
  const { addresses, defaultAddressId } = await fetchMemberAddressesData(flowAPI);
  dispatch(setSavedAddresses({ addresses, defaultAddressId }));
  dispatch(setIsLoadingAddressesFromServer({ isLoadingAddressesFromServer: false }));

  const defaultAddress = addresses.find((address) => address.id === defaultAddressId);
  if (defaultAddress && defaultAddress.addressLine1) {
    dispatch(
      setDeliveryAddress({
        address: convertMembersAddressToOloAddress(defaultAddress),
      }),
    );
  }
}
